@import 'scss/media-queries';
@import 'scss/typography';
@import 'scss/mixins';

.campaignBanner {
  align-items: center;
  background-color: var(--background-color);
  position: relative;
  padding: var(--space-3) var(--space-6);
  justify-self: center;
  width: 100%;

  &._loading {
    @include placeholder;
  }

  &.isClickable {
    cursor: pointer;
  }

  // hashcode suffix comes from API hence hashcode is part of class name to make it dynamic
  &._theme_E5FB70 {
    --background-color: var(--primary);
    --text-color: var(--text-color);
  }
  &._theme_2892F4 {
    --background-color: var(--secondary);
    --text-color: var(--neutral-6);
  }
  &._theme_D6F8D6 {
    --background-color: var(--secondary-variant-2);
    --text-color: var(--text-color-grey-1);
  }
  &._theme_F9ECA1 {
    --background-color: var(--warning-1);
    --text-color: var(--text-color-grey-1);
  }
  &._theme_00644C {
    --background-color: var(--success-1);
    --text-color: var(--neutral-6);
  }
  &._theme_000000 {
    --background-color: var(--primary-variant-2);
    --text-color: var(--neutral-6);
  }

  @include until-tablet {
    padding: var(--space-3) var(--space-4);
  }

  .bannerBox {
    display: flex;
    flex-direction: row;
    gap: var(--space-4);
    align-items: center;
    overflow: hidden;
    justify-self: center;
    width: fit-content;
    margin: 0 auto;

    @include until-tablet {
      text-align: left;
      margin: unset;
    }

    @include until-phone {
      gap: var(--space-2);
    }

    .bannerContent {
      display: flex;
      flex-direction: row;
      text-align: left;
      gap: var(--space-4);
      color: var(--text-color);

      @include until-tablet {
        flex-direction: column;
        gap: 0;
      }

      .title {
        @include label-md-bold;
        @include until-tablet {
          @include label-sm-bold;
        }
      }

      .ritchText {
        display: flex;
        gap: var(--space-1);

        a {
          text-decoration: underline;
          text-decoration-color: var(--text-color);
        }
      }

      .text {
        @include label-md;
        @include until-tablet {
          @include label-sm;

          margin-top: var(--space-1);
        }
      }
    }
  }

  .image {
    width: 80px;
    height: 40px;
    flex: 0 0 80px;
  }

  .click {
    display: flex;

    svg {
      color: var(--text-color) !important;
    }
  }
}
