@import 'scss/media-queries';

.container {
  width: 100%;
  padding: 0 var(--space-15);

  @include until-desktop {
    padding: 0 var(--space-1);
  }

  @include until-tablet {
    padding: 0;
  }

  @include until-phone {
    overflow: auto;
  }
}

.categoryActivityTiles {
  display: flex;
  gap: var(--space-6);
  justify-content: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  > button {
    width: 92px;
  }

  > button:nth-child(n + 10):not(:last-child) {
    display: none;
  }

  @include until-desktop {
    > button:nth-child(n + 8):not(:last-child) {
      display: none;
    }
  }

  @include until-tablet {
    > button:nth-child(n + 6):not(:last-child) {
      display: none;
    }
  }

  @include until-phone {
    display: flex;
    flex: 1 1;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 0 var(--space-4);
    gap: var(--space-2);

    > button:nth-child(n) {
      display: flex !important;
      width: 78px;
    }

    > button:nth-child(n + 8):not(:last-child) {
      display: none !important;
    }
  }
}

.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(6, minmax(92px, 1fr));
  justify-content: space-between;
  gap: var(--space-6);
  padding: var(--space-4) var(--space-16) var(--space-12);

  @include until-phone {
    padding: var(--space-6) var(--space-5);
    column-gap: var(--space-1);
    row-gap: var(--space-6);
    grid-template-columns: repeat(auto-fill, minmax(78px, 1fr));
  }
}
