@import 'scss/typography';
@import 'scss/media-queries';

.destinationsByRegion {
  position: relative;

  .title {
    @include header-3;
    margin-bottom: var(--space-4);
  }

  .headerContent {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: var(--space-6);
    margin-bottom: var(--space-6);
  }

  .chipContainer {
    display: flex;
    gap: var(--space-2);
    flex: 1;
    overflow-x: auto;
    padding: 2px;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    > button {
      margin: 0;
    }
  }

  .destinationCardContainer {
    display: grid;
    grid-template-columns: repeat(6, minmax(108px, 1fr));
    gap: var(--space-4);
  }

  .seeAllBtn {
    margin-top: var(--space-4);
  }

  .btnContainer {
    padding: 0 var(--space-4);
  }

  @include until-tablet {
    padding: 0;
    .headerContent {
      margin-bottom: 0;
    }

    .title {
      padding: 0 var(--space-4);
      margin-bottom: var(--space-2);
    }

    .chipContainer {
      padding: 2px var(--space-4);
    }
  }
}
